/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikErrors, FormikProps, FormikTouched } from 'formik'
import { isObject, isString, startCase, toLower } from 'lodash'

export type ErrorWithLink = {
  message: string
  link: JSX.Element
}
type FormErrors =
  | string
  | string[]
  | FormikErrors<any>
  | FormikErrors<any>[]
  | ErrorWithLink
  | ErrorWithLink[]
  | undefined

// types don't work right in Formik 2.2.9 (https://github.com/jaredpalmer/formik/issues/3355)
type NestedErrorWorkaround = 'string' | Record<string, string> | undefined
type NestedTouchedWorkaround = boolean | Record<string, boolean> | undefined

function composeHelperText(defaultText: string, error: FormErrors, touched: boolean | undefined) {
  if (touched && error) {
    return concatErrors(error)
  }

  return defaultText
}

function concatErrors(error: FormErrors) {
  if (!error) {
    return ''
  }

  if (isString(error)) {
    return error
  }

  // Verify error fits the ErrorWithLink type
  if (
    isObject(error) &&
    Object.prototype.hasOwnProperty.call(error, 'message') &&
    Object.prototype.hasOwnProperty.call(error, 'link')
  ) {
    const errorWithLink = error as ErrorWithLink
    const { message, link } = errorWithLink
    return (
      <>
        {message} {link}
      </>
    )
  }

  // todo - handle objects and arrays when it become necessary
  return ''
}

function composeHelperTextWithError(defaultText: string, error: FormErrors, touched: boolean | undefined) {
  if (touched && error) {
    return { error: true, helperText: concatErrors(error) }
  }

  return { error: false, helperText: defaultText }
}

function composeNestedHelperTextWithError<T>(
  defaultText: string,
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  parentKey: keyof T,
  nestedKey: string,
  ignoreTouched = false,
) {
  const isTouched = getNestedTouched(touched, parentKey, nestedKey)
  const error = getNestedObjectError(errors, parentKey, nestedKey)

  return composeHelperTextWithError(defaultText, error, ignoreTouched ? true : isTouched)
}

function getNestedObjectError<T>(errors: FormikErrors<T>, parentKey: keyof T, nestedFieldKey: string) {
  const parentError = errors[parentKey] as NestedErrorWorkaround
  if (!parentError) return

  if (typeof parentError === 'string') {
    return parentError
  }

  return parentError[nestedFieldKey]
}

function getNestedTouched<T>(touched: FormikTouched<T>, parentKey: keyof T, nestedFieldKey: string) {
  const parentTouched = touched[parentKey] as NestedTouchedWorkaround
  if (!parentTouched) return

  if (typeof parentTouched === 'boolean') {
    return parentTouched
  }

  return parentTouched[nestedFieldKey]
}

/** @deprecated use getFieldProps from Formik */
function getFieldProps<T>(name: keyof T, formikProps: FormikProps<T>) {
  const { handleChange, handleBlur, values, errors, touched } = formikProps

  // TODO: I made this while refactoring Intake. It should utilize getFieldProps from FormikProps and fullWidth: true doesn't belong here. But for the sake of merging the intake refactor this will be a FIF follow-on
  return {
    onChange: handleChange,
    onBlur: handleBlur,
    fullWidth: true,
    name,
    value: values[name],
    error: (errors[name] && touched[name]) as boolean,
    helperText: touched[name] ? (errors[name] as string) : '',
  }
}

const oopsRequired = (fieldName: string) => `Oops, you forgot your ${fieldName}`
const makeSelection = 'Please make a selection'

const composeSelectOptionsFromEnum = <T extends Record<string, string | number>>(enumObject: T) => {
  return Object.values(enumObject).reduce((acc, value) => {
    if (typeof value === 'string') {
      acc[value as keyof T] = startCase(toLower(value))
    }
    return acc
  }, {} as Record<keyof T, string>)
}

export const formService = {
  composeHelperText,
  composeHelperTextWithError,
  composeNestedHelperTextWithError,
  composeSelectOptionsFromEnum,
  oopsRequired,
  makeSelection,
  getFieldProps,
}
