import { useState } from 'react'

import { CredentialingChecklistDataPoint } from '@nuna/api'
import { Stack } from '@nuna/tunic'

import { ChecklistWorkHistoryList } from '../Elements/ChecklistWorkHistoryList'
import { CredentialingVerificationForm } from '../Forms/CredentialingVerificationForm'

export const ChecklistWorkHistoryDialogContent = ({ data }: { data: CredentialingChecklistDataPoint }) => {
  const [selectedItem, setSelectedItem] = useState<CredentialingChecklistDataPoint>(data)

  return (
    <Stack direction={'row'} sx={{ height: '100%' }}>
      <ChecklistWorkHistoryList selectedItem={selectedItem} setSelectedEntity={setSelectedItem} />
      <CredentialingVerificationForm selectedItem={selectedItem} />
    </Stack>
  )
}
