import { styled } from '@mui/material'
import { useState } from 'react'

import { MessageComposerContextProvider } from '@nuna/messaging'
import { BelowTablet, PageContent, PageHeader, PageWrapper, Radio, RadioGroup } from '@nuna/tunic'

import { SessionData } from '../../types'
import { InputHeading } from './components/InputHeading'
import { MissedSessionForm } from './components/MissedSessionForm'
import { SessionDetailHeader } from './components/SessionDetailHeader'
import { SessionNoteForm } from './components/SessionNoteForm'

interface Props {
  sessionData: SessionData
  onCanceled: () => void
  onCancelEdit: () => void
  onPublished: (sessionId: string) => void
  onMarkedAsNoShow: () => void
}

export function SessionNote({ onCanceled, onCancelEdit, onPublished, onMarkedAsNoShow, sessionData }: Props) {
  const [isEditing, setIsEditing] = useState(!sessionData.sessionId)
  const [didSessionHappen, setDidSessionHappen] = useState(true)

  const handleCancelEdit = () => {
    if (sessionData.sessionId) {
      setIsEditing(false)
    } else {
      onCancelEdit()
    }
  }

  return (
    <MobileOptimizedPageWrapper>
      <PageHeader>
        <SessionDetailHeader
          isEditing={isEditing}
          sessionData={sessionData}
          handleEditClick={() => setIsEditing(!isEditing)}
        />
      </PageHeader>

      <PageContent>
        <PagePadding>
          {!sessionData.sessionId && (
            <>
              <InputHeading sx={{ mb: 2 }}>Did this session happen?</InputHeading>
              <RadioGroup className="v-align mb-1">
                <Radio onChange={() => setDidSessionHappen(true)} checked={didSessionHappen}>
                  Yes
                </Radio>
                <Radio
                  className="horizontal ml-2"
                  onChange={() => setDidSessionHappen(false)}
                  checked={!didSessionHappen}
                >
                  No
                </Radio>
              </RadioGroup>
            </>
          )}

          {didSessionHappen ? (
            <SessionNoteForm
              isEditing={isEditing}
              sessionData={sessionData}
              onPublished={onPublished}
              onCancelEdit={handleCancelEdit}
              setIsEditing={setIsEditing}
            />
          ) : (
            <MessageComposerContextProvider
              richTextEditorProps={{ disableAutoLink: true }}
              participantLoginIds={[sessionData.patient.loginId ?? '', sessionData.provider.loginId]}
            >
              <MissedSessionForm
                sessionData={sessionData}
                onMarkedAsNoShow={onMarkedAsNoShow}
                onCanceled={onCanceled}
                onCancelEdit={onCancelEdit}
              />
            </MessageComposerContextProvider>
          )}
        </PagePadding>
      </PageContent>
    </MobileOptimizedPageWrapper>
  )
}

// Something wacky is happening with the PageWrapper styles after some Provider Onboarding changes.
// This makes it easier for providers to use session notes on mobile. A more comprehensive fix for Harmony mobile is needed.
const MobileOptimizedPageWrapper = styled(PageWrapper)`
  @media (${BelowTablet}) {
    height: calc(100% - 60px); // 60px is the height of the header.
  }
`

const PagePadding = styled('div')`
  padding: var(--spacing-3) var(--spacing-2);

  @media (${BelowTablet}) {
    padding: var(--spacing-1) var(--spacing-half);
  }
`
