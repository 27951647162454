import { styled } from '@mui/material'
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'

import {
  AlterSessionActionType,
  AppointmentCurrentStatus,
  ManageSessionMutationVariables,
  WhoIsResponsibleForPayment,
  useCalculateSessionFinancialsMutation,
  useInternalAppointmentDetailQuery,
  useManageSessionMutation,
  useResetAppointmentMutation,
} from '@nuna/api'
import { AdminPayoutAdjustmentForm, CancelAsAdminForm, MarkNoShowAsAdminForm, WriteOffForm } from '@nuna/appointment'
import { errorService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import { Confirm, ConfirmRef, Drawer, DropdownButton, MenuItemProps, toast } from '@nuna/tunic'

import { DropdownMenu } from '../../../shared/DropdownMenu'
import { DeleteSessionForm } from './components/DeleteSessionForm'
import { SwitchAppointmentToCashPayForm } from './components/SwitchAppointmentToCashPayForm'
import { SwitchAppointmentToEapForm } from './components/SwitchAppointmentToEapForm'

type OpenDrawer =
  | 'none'
  | 'cancel'
  | 'no-show'
  | 'write-off'
  | 'admin-adjustment'
  | 'change-to-eap'
  | 'change-to-cash'
  | 'delete-note'
type ChangeNeedingConfirmation = 'change-to-insurance' | 'reset-appointment'

interface ManageAppointmentProps {
  appointmentId: string
}

const DrawerContent = styled('div')`
  padding: 1.6rem;
`

export function ManageAppointment({ appointmentId }: ManageAppointmentProps) {
  const confirmRef = useRef<ConfirmRef>(null)
  const [openDrawer, setOpenDrawer] = useState<OpenDrawer>('none')
  const [confirmationType, setConfirmationType] = useState<ChangeNeedingConfirmation | null>(null)
  const { adminFees, adminFinancials, manageSessions } = useFeatureFlags()

  const { data, refetch } = useInternalAppointmentDetailQuery({
    variables: { id: appointmentId, includeFinancials: true },
    fetchPolicy: 'network-only',
  })

  const [resetAppointment] = useResetAppointmentMutation()
  const [manageSession] = useManageSessionMutation()
  const [calculateFinancials] = useCalculateSessionFinancialsMutation()

  const appointment = useMemo(() => data?.internalAppointment, [data])
  const session = useMemo(() => appointment?.session, [appointment])
  const hasAppointmentHappened = useMemo(() => moment(appointment?.startDatetime).isBefore(moment()), [appointment])
  const writtenOffDate = useMemo(() => appointment?.writtenOffDate, [appointment])
  const whoIsResponsibleForPayment = appointment?.whoIsResponsibleForPayment
  const currentStatus = appointment?.currentStatus

  const closeDrawer = useCallback(() => setOpenDrawer('none'), [])

  const handleSwitchComplete = useCallback(() => {
    refetch()
    closeDrawer()
  }, [closeDrawer, refetch])

  const handleReset = useCallback(async () => {
    try {
      const { data: resetResult } = await resetAppointment({
        variables: { appointmentId: appointment?.id ?? '' },
        refetchQueries: ['InternalAppointmentDetail'],
      })

      const warning = resetResult?.resetAppointment?.warning

      if (warning) toast.caution(warning)
      else toast.success(`Appointment reset`)
    } catch (error) {
      console.error(error)
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to reset appointment'))
    }
  }, [resetAppointment, appointment])

  const handleManageSession = useCallback(
    async (variables: Omit<ManageSessionMutationVariables, 'id'>) => {
      try {
        await manageSession({ variables: { id: session?.id ?? '', ...variables } })
        toast.success('Session updated')
        await refetch()
      } catch (error) {
        console.error(error)
        toast.urgent(errorService.transformGraphQlError(error, 'Failed to update session'))
      }
    },
    [session, manageSession, refetch],
  )

  const handleCalculateFinancials = useCallback(async () => {
    try {
      await calculateFinancials({ variables: { appointmentId } })
      toast.success('Session financials recalculated')
      await refetch()
    } catch (error) {
      console.error(error)
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to recalculate session financials'))
    }
  }, [appointmentId, calculateFinancials, refetch])

  const confirmChange = useCallback(
    (changeType: ChangeNeedingConfirmation) => {
      setConfirmationType(changeType)
      confirmRef?.current?.open(async confirmed => {
        if (confirmed) {
          if (changeType === 'reset-appointment') {
            await handleReset()
          } else if (changeType === 'change-to-insurance') {
            await handleManageSession({
              actionType: AlterSessionActionType.ChangeWhoIsResponsibleForPayment,
              actionData: { whoIsResponsibleForPayment: WhoIsResponsibleForPayment.Insurance },
            })
          }
        }
        setConfirmationType(null)
      })
    },
    [handleReset, handleManageSession],
  )

  const menuItems: MenuItemProps[] = useMemo<MenuItemProps[]>(() => {
    const items: MenuItemProps[] = []

    if (currentStatus === AppointmentCurrentStatus.Active) {
      if (session) {
        if (manageSessions && whoIsResponsibleForPayment !== WhoIsResponsibleForPayment.Company) {
          items.push({ button: true, onClick: () => setOpenDrawer('change-to-eap'), children: 'Switch to EAP' })
        }

        if (manageSessions && whoIsResponsibleForPayment !== WhoIsResponsibleForPayment.Insurance) {
          items.push({
            button: true,
            onClick: () => confirmChange('change-to-insurance'),
            children: 'Switch to Insurance',
          })
        }

        if (manageSessions && whoIsResponsibleForPayment !== WhoIsResponsibleForPayment.Client) {
          items.push({
            button: true,
            children: 'Switch to Cash Pay',
            onClick: () => setOpenDrawer('change-to-cash'),
          })
        }

        if (manageSessions) {
          items.push({ button: true, children: 'Delete Note', onClick: () => setOpenDrawer('delete-note') })
        }

        items.push({ button: true, onClick: handleCalculateFinancials, children: 'Recalculate Financials' })
      } else {
        items.push({ button: true, onClick: () => setOpenDrawer('cancel'), children: 'Cancel Appointment' })
        if (hasAppointmentHappened) {
          items.push({ button: true, onClick: () => setOpenDrawer('no-show'), children: 'Mark No-Show' })
        }
      }

      if (adminFees && !writtenOffDate) {
        items.push({ button: true, onClick: () => setOpenDrawer('write-off'), children: 'Write Off Appointment' })
      }
    } else {
      items.push({ button: true, onClick: () => confirmChange('reset-appointment'), children: 'Reset Appointment' })
    }

    if (adminFinancials) {
      items.push({
        button: true,
        onClick: () => setOpenDrawer('admin-adjustment'),
        children: 'Add Adjustment Payout',
      })
    }

    return items
  }, [
    currentStatus,
    session,
    adminFees,
    adminFinancials,
    manageSessions,
    hasAppointmentHappened,
    writtenOffDate,
    whoIsResponsibleForPayment,
    confirmChange,
    handleCalculateFinancials,
  ])

  return (
    <>
      {menuItems.length > 0 && (
        <DropdownMenu dropdownButton={<DropdownButton>Admin Actions</DropdownButton>} menuItems={menuItems} />
      )}
      <Confirm ref={confirmRef}>
        <div className="p-2">
          {confirmationType === 'reset-appointment' && (
            <>
              <p className="mb-3">Are you sure you want to reset this appointment?</p>
              <p>The status will be reset back to Active and all cancelation or no-show actions will be cleared.</p>
            </>
          )}
          {confirmationType === 'change-to-insurance' && (
            <div>Are you sure you want to change the payer of this appointment to insurance?</div>
          )}
        </div>
      </Confirm>
      {appointment && (
        <Drawer isOpen={openDrawer !== 'none'} onClose={closeDrawer} size="400px">
          <DrawerContent>
            {openDrawer === 'cancel' && (
              <CancelAsAdminForm appointment={appointment} onComplete={handleSwitchComplete} />
            )}
            {openDrawer === 'no-show' && (
              <MarkNoShowAsAdminForm appointment={appointment} onComplete={handleSwitchComplete} />
            )}
            {openDrawer === 'write-off' && (
              <WriteOffForm appointmentId={appointment.id} onComplete={handleSwitchComplete} />
            )}
            {openDrawer === 'admin-adjustment' && (
              <AdminPayoutAdjustmentForm appointmentId={appointment.id} onComplete={handleSwitchComplete} />
            )}
            {openDrawer === 'change-to-eap' && (
              <SwitchAppointmentToEapForm appointmentId={appointment.id} onComplete={handleSwitchComplete} />
            )}
            {openDrawer === 'change-to-cash' && (
              <SwitchAppointmentToCashPayForm
                appointmentId={appointment.id}
                patientId={appointment.patient.id}
                onComplete={handleSwitchComplete}
              />
            )}
            {openDrawer === 'delete-note' && (
              <DeleteSessionForm sessionId={session?.id ?? ''} onComplete={handleSwitchComplete} />
            )}
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}
