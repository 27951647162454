import React, { useState } from 'react'

import { CredentialingChecklistDataPoint } from '@nuna/api'
import { Stack } from '@nuna/tunic'

import { ChecklistEducationList } from '../Elements/ChecklistEducationList'
import { CredentialingVerificationForm } from '../Forms/CredentialingVerificationForm'

export const ChecklistEducationDialogContent = ({ data }: { data: CredentialingChecklistDataPoint }) => {
  const [selectedItem, setSelectedItem] = useState<CredentialingChecklistDataPoint>(data)
  return (
    <Stack direction={'row'} sx={{ height: '100%' }}>
      <ChecklistEducationList selectedItem={selectedItem} setSelectedEntity={setSelectedItem} />
      <CredentialingVerificationForm selectedItem={selectedItem} />
    </Stack>
  )
}
