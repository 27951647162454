import { SxProps, styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'lodash'
import moment from 'moment'
import { ReactNode, useEffect } from 'react'

import { useGenerateAutonoteMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  Box,
  Card,
  FillButton,
  IconAISparkles,
  IconNotebook,
  Stack,
  TextButton,
  TextField,
  Typography,
  TypographyProps,
  body2,
  interactiveText,
  paragraphsLineHeight,
  toast,
} from '@nuna/tunic'

import { useSaveSessionDraft } from '../../../hooks/useSaveSessionDraft'
import { SessionData } from '../../../types'
import { hasAutonote, hasAutonoteError, isAutonoteDelayed, isAutonoteLoading } from '../../../utils'
import { DraftSavingStatus } from '../../DraftSavingStatus'
import loadingGifSrc from '../img/auto-note-loading.gif'
import sadImgSrc from '../img/sad-laptop.png'
import { InputHeading } from './InputHeading'
import { SessionNoteFormValues } from './SessionNoteForm'

const { composeHelperTextWithError } = formService

interface Props {
  sessionData: SessionData
  disabled?: boolean
  renderCardFooter?: ReactNode
}

export function NoteField({ sessionData, renderCardFooter = null, disabled = false }: Props) {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext<SessionNoteFormValues>()
  const { loading, lastSavedAt } = useSaveSessionDraft({
    draft: {
      ...values,
      therapyTypeSpecialtyId: values.therapyTypeSpecialtyId ?? '',
      schemaVersion: '2.0',
      note: sessionData.inSessionNotes ?? null,
    },
    appointmentId: sessionData.appointmentId,
    patientId: sessionData.patient.id,
    sessionId: sessionData.sessionId,
  })
  const [generateAutonote, { loading: generateAutonoteLoading }] = useGenerateAutonoteMutation()

  useEffect(() => {
    if (sessionData.workingDraft && isNil(values.workingDraft)) {
      // sets the value if the auto note goes from a loading state to being available
      setFieldValue('workingDraft', sessionData.workingDraft)
    }
  }, [sessionData.workingDraft, setFieldValue, values.workingDraft])

  const handleAutonoteBypassClick = () => {
    setFieldValue('workingDraft', sessionData.inSessionNotes ?? '')
  }

  const handleGenerateDraftClick = async () => {
    try {
      const response = await generateAutonote({ variables: { appointmentId: sessionData.appointmentId } })

      if (response.data?.generateAutonote) {
        toast.success('Your note is being generated')
      } else {
        throw new Error('Autonote generation failed')
      }
    } catch (e) {
      toast.urgent('Error generating autonote. Please try again or contact support.')
      console.error('Error generating autonote', e)
    }
  }

  return (
    <StyledCard data-testid="session-note-container" sx={{ py: 3, px: { xs: 2, lg: 3 } }} depth={-0.5}>
      {(() => {
        if (!isNil(values.workingDraft)) {
          return (
            <>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <InputHeading
                  id="note-input-heading"
                  data-testid="note-input-heading"
                  sx={{ mb: 0, display: 'flex', alignItems: 'center' }}
                >
                  {(() => {
                    if (sessionData.sessionSubmittedAt) {
                      return (
                        <>
                          <IconNotebook className="mr-xs" size={20} color={interactiveText} /> Submitted{' '}
                          {sessionData.sessionSubmittedAt.format('M/DD/YY')}
                        </>
                      )
                    }

                    if (hasAutonote(sessionData)) {
                      return (
                        <>
                          <IconAISparkles className="mr-1" size={20} color={interactiveText} /> Tava Scribe draft
                        </>
                      )
                    }

                    return (
                      <>
                        <IconNotebook className="mr-xs" size={20} color={body2} /> Session note draft
                      </>
                    )
                  })()}
                </InputHeading>

                {!sessionData.sessionId && (
                  <Typography component="span" sx={{ fontWeight: 'medium', ml: 'auto' }}>
                    Submit by {moment(sessionData.scheduledStartTime).add(72, 'hours').format('M/DD')}
                  </Typography>
                )}
              </Stack>

              {sessionData.sessionId && disabled ? (
                <div className="pre-line paragraphs text-secondary mt-3">{values.workingDraft}</div>
              ) : (
                <TextField
                  disabled={disabled}
                  multiline
                  rows={4}
                  value={values.workingDraft}
                  onChange={handleChange}
                  name="workingDraft"
                  variant="outlined"
                  onBlur={handleBlur}
                  className="fs-exclude"
                  data-testid="session-note-input"
                  aria-labelledby="note-input-heading"
                  {...composeHelperTextWithError('', errors.workingDraft, touched.workingDraft)}
                />
              )}
            </>
          )
        }

        if (isAutonoteLoading(sessionData) && !isAutonoteDelayed(sessionData)) {
          return (
            <Box sx={statusContainer}>
              <Box component="img" src={loadingGifSrc} alt="" sx={{ ...statusImage, mt: 3, maxWidth: 300 }} />

              <Typography {...statusHeading}>Tava Scribe is generating the session note...</Typography>

              <Typography>
                Hang tight while Tava Scribe works on your note. Drafts are usually generated within 15 seconds but can
                sometimes take longer.
              </Typography>

              <Typography {...tertiaryStatusText}>
                Taking too long? <TextButton onClick={handleAutonoteBypassClick}>Click here</TextButton> to write your
                own.
              </Typography>
            </Box>
          )
        }

        if ((isAutonoteLoading(sessionData) && isAutonoteDelayed(sessionData)) || hasAutonoteError(sessionData)) {
          return (
            <Box sx={statusContainer}>
              <Box component="img" src={sadImgSrc} alt="" sx={{ ...statusImage, maxWidth: 145, mb: 3 }} />

              <Typography {...statusHeading}>Your note is taking an unusually long time. We can help.</Typography>

              <Typography>
                We're sorry about the inconvenience. Please contact our{' '}
                <TextButton onClick={() => supportService.openChat()}>support team</TextButton> to have your note
                regenerated as quickly as possible. Or you can{' '}
                <TextButton variant="secondary" onClick={handleAutonoteBypassClick}>
                  manually write
                </TextButton>{' '}
                the note and disregard Scribe for this session.{' '}
              </Typography>

              <Typography {...tertiaryStatusText}>
                If you've already contacted our support team, they will notify you as soon as your note is ready.
              </Typography>
            </Box>
          )
        }

        return (
          <Box sx={{ ...statusContainer }}>
            <InputHeading sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <IconAISparkles className="mr-1" size={20} color={interactiveText} /> Tava Scribe draft
            </InputHeading>

            <FillButton isLoading={generateAutonoteLoading} onClick={handleGenerateDraftClick}>
              <IconAISparkles className="mr-1" size={20} />
              Generate draft
            </FillButton>

            <Typography sx={{ mt: 4 }}>
              Drafts are usually generated within 15 seconds but can sometimes take longer.
              <br />
              Closing any open session tabs can help prevent delays.
            </Typography>

            <Typography {...tertiaryStatusText}>
              Or you can <TextButton onClick={handleAutonoteBypassClick}>write your note</TextButton> without the aid of
              Tava Scribe for this session.
            </Typography>
          </Box>
        )
      })()}
      {values.workingDraft && !disabled && (
        <DraftSavingStatus sx={{ pt: 1 }} loading={loading} lastSavedAt={lastSavedAt} />
      )}
      {renderCardFooter}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  textarea {
    line-height: ${paragraphsLineHeight}; // TODO: Currently didn't have a good way of handling this with SX until we are using MUI's textfield
  }
`

const statusContainer: SxProps = {
  pb: 1,
  lineHeight: paragraphsLineHeight,
}

const statusImage: SxProps = {
  display: 'block',
  mx: 'auto',
  width: '100%',
}

const statusHeading: TypographyProps = {
  component: 'h5',
  variant: 'h4',
  sx: theme => ({
    color: theme.palette.text.primary,
    mb: 3,
  }),
}

const tertiaryStatusText: TypographyProps = {
  variant: 'caption',
  component: 'p',
  sx: {
    fontWeight: 'medium',
    mt: 5,
  },
}
