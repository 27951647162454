import { first } from 'lodash'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import {
  CredentialingChecklist,
  ProviderCredentialingVerification,
  useCredentialingChecklistQuery,
  useProviderCredentialingVerificationsQuery,
} from '@nuna/api'

type CredentialingChecklistContext = {
  checklist: CredentialingChecklist | null
  isLoading: boolean
  verifications: ProviderCredentialingVerification[] | null
  isLoadingVerifications: boolean
}

export const CredentialingChecklistContext = createContext<CredentialingChecklistContext | null>(null)

interface Props {
  children: ReactNode
  providerId: string
}
export function CredentialingChecklistContextProvider({ children, providerId }: Props) {
  const [checklist, setChecklist] = useState<CredentialingChecklist | null>(null)
  const [verifications, setVerifications] = useState<ProviderCredentialingVerification[] | null>(null)

  const { data, loading } = useCredentialingChecklistQuery({
    variables: { providerId },
    skip: !providerId,
  })

  const providerVerifiableDataId = data?.credentialingChecklistData?.npi
    ? first(data.credentialingChecklistData.npi)?.providerVerifiableDataId
    : ''

  const { data: verificationData, loading: verificationsLoading } = useProviderCredentialingVerificationsQuery({
    variables: { providerVerifiableDataId: providerVerifiableDataId ?? '' },
    skip: !providerVerifiableDataId,
  })

  useEffect(() => {
    if (data) {
      setChecklist(data.credentialingChecklistData)
    }
    if (verificationData) {
      setVerifications(verificationData.providerCredentialingVerifications)
    }
  }, [data, verificationData])

  return (
    <CredentialingChecklistContext.Provider
      value={{
        checklist,
        isLoading: loading,
        verifications,
        isLoadingVerifications: verificationsLoading,
      }}
    >
      {children}
    </CredentialingChecklistContext.Provider>
  )
}

export function useCredentialingChecklistContext() {
  const context = useContext(CredentialingChecklistContext)
  if (!context) {
    throw new Error('useCredentialingContext must be used within a CredentialingContextProvider')
  }
  return context
}
