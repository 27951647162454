import { styled } from '@mui/material'
import moment from 'moment'
import { ComponentProps, FocusEvent } from 'react'

import { triggerChangeEvent } from '../../utils/event'
import { type InputStylesProps, type RawInputProps, inputStyles } from '../BaseInput/BaseInput'

export const timeRegExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s(AM|PM|am|pm)$/gim

type Props = Omit<ComponentProps<typeof Input>, 'type'> & RawInputProps

export function TimeField({ onBlur, ref, onChange, inputProps, ...props }: Props) {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      const parsed = parseTime(e.target.value)
      if (parsed) {
        triggerChangeEvent(e.target, parsed)
      }
      onBlur(e)
    }
  }

  return <Input {...props} {...inputProps} ref={ref} type="text" onBlur={handleBlur} onChange={onChange} />
}

export function parseTime(time: string | moment.Moment) {
  const mt = moment(time, 'h:mm A')
  if (!mt.isValid()) {
    return undefined
  }

  return mt.format('h:mm A')
}

export function validateTime(time: string) {
  const mt = moment(time, 'h:mm A')
  return mt.isValid()
}

const Input = styled('input')<InputStylesProps>`
  ${inputStyles}
`
