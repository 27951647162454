import { lowerCase, startCase } from 'lodash'
import { ChangeEvent, useState } from 'react'

import {
  AlterSessionActionData,
  AlterSessionActionType,
  AppointmentCurrentStatus,
  useManageSessionMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { Checkbox, FillButton, Select, makeTypographyComponent, toast } from '@nuna/tunic'

interface DeleteSessionFormProps {
  sessionId: string
  onComplete: () => void
}

type FormValues = Pick<
  AlterSessionActionData,
  'appointmentStatus' | 'shouldPayProvider' | 'exercisedContractId' | 'whoIsResponsibleForPayment'
>

export function DeleteSessionForm({ sessionId, onComplete }: DeleteSessionFormProps) {
  const [formValues, setFormValues] = useState<FormValues>({
    appointmentStatus: AppointmentCurrentStatus.Active,
    shouldPayProvider: false,
    exercisedContractId: null,
    whoIsResponsibleForPayment: null,
  })
  const [manageSession, { loading: mutationLoading }] = useManageSessionMutation()

  const handleDeleteSession = async () => {
    try {
      await manageSession({
        variables: { id: sessionId, actionType: AlterSessionActionType.ArchiveSession, actionData: formValues },
      })
      toast.success('Session note deleted')
      onComplete()
    } catch (error) {
      console.error(error)
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to delete session note'))
    }
  }

  return (
    <div>
      <h1 className="h5 mb-0 pb-2">Delete Session Note</h1>
      <p className="text-secondary caption">
        This will permanently delete the session note and allow you to reset the appointment status. If it should have
        been a cancel, select the correct cancel reason.
      </p>

      <FormSection>
        <Select
          value={formValues.appointmentStatus ?? ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            const appointmentStatus = e.target.value as AppointmentCurrentStatus
            setFormValues(current => ({
              ...current,
              appointmentStatus,
              shouldPayProvider:
                appointmentStatus === AppointmentCurrentStatus.Active ? false : current.shouldPayProvider,
            }))
          }}
          label="Appointment Status"
        >
          {Object.values(AppointmentCurrentStatus)
            .filter(status => ![AppointmentCurrentStatus.Undocumented].includes(status))
            .map(status => (
              <option value={status} key={status}>
                {startCase(lowerCase(status))}
              </option>
            ))}
        </Select>
      </FormSection>
      {formValues.appointmentStatus !== AppointmentCurrentStatus.Active && (
        <FormSection>
          <Checkbox
            checked={!!formValues.shouldPayProvider}
            onChange={() =>
              setFormValues(current => ({ ...current, shouldPayProvider: !formValues.shouldPayProvider }))
            }
          >
            Pay Provider
          </Checkbox>
        </FormSection>
      )}

      <div className="pt-4">
        <FillButton isLoading={mutationLoading} onClick={handleDeleteSession} className="full-width">
          Delete Session Note
        </FillButton>
      </div>
      <FormSection className="text-secondary caption italic">
        *For clients without a cancel policy, setting that the provider should get paid for the cancelation will set the
        date this cancel action took place as 30 minutes before the session start time.*
      </FormSection>
    </div>
  )
}

const FormSection = makeTypographyComponent('pt-2', 'div')
