import { NoteGenerationSkippedReason } from '@nuna/api'
import { ContextualAlert, ContextualAlertProps, IconInfo } from '@nuna/tunic'

import { SessionData } from '../../../types'

interface Props extends Omit<ContextualAlertProps, 'children'> {
  sessionData: SessionData
}

export function SkippedNoteAlert({
  sessionData,
  intent = 'caution',
  variant = 'outlined',
  icon = <IconInfo />,
  ...props
}: Props) {
  const { sessionSummary } = sessionData

  if ((sessionSummary && !sessionSummary.noteGenerationSkippedReason) || sessionData.sessionId) {
    return null
  }

  return (
    <ContextualAlert intent={intent} variant={variant} icon={icon} {...props}>
      {(() => {
        if (!sessionSummary) {
          return sessionData.addressId !== 'Virtual'
            ? 'Tava Scribe is not available for in-person at this time'
            : 'No video session was found for this appointment'
        }

        switch (sessionSummary.noteGenerationSkippedReason) {
          case NoteGenerationSkippedReason.NoTranscript:
            return 'A transcript was not found for this session, so no note was generated. Please contact support if you believe this is an error.'
          case NoteGenerationSkippedReason.PatientOptedOut:
            return `${sessionData.patient.firstName} has opted out of Tava Scribe, so no note was generated.`
          case NoteGenerationSkippedReason.ProviderOptedOut:
            return 'Tava Scribe was disabled for this session, so no note was generated.'
          default:
            return 'No note was generated for this session.'
        }
      })()}
    </ContextualAlert>
  )
}
