import { Phone, Typography, TypographyProps } from '@nuna/tunic'

export function InputHeading({ sx, ...props }: TypographyProps) {
  return (
    <Typography
      component="h3"
      variant="h6"
      sx={{
        color: 'text.primary',
        fontFamily: 'body1.fontFamily',
        [`@media (${Phone})`]: {
          fontSize: 'body1.fontSize',
        },
        mb: 0,
        ...sx,
      }}
      {...props}
    />
  )
}
