import { Form, Formik } from 'formik'
import { capitalize } from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import {
  CredentialingChecklistDataPoint,
  ProviderCredentialingVerificationInput,
  ProviderCredentialingVerificationStatus,
  ProviderCredentialingVerificationType,
  useSaveProviderCredentialingVerificationMutation,
} from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { Box, DatePicker, FillButton, HelperText, Select, Stack, TextField, toast } from '@nuna/tunic'

import { useCredentialingChecklistContext } from '../Context/CredentialingChecklistContextProvider'

const { composeHelperText, composeSelectOptionsFromEnum } = formService

const basicSelectOptions = [
  ProviderCredentialingVerificationStatus.ManualVerification,
  ProviderCredentialingVerificationStatus.Verified,
  ProviderCredentialingVerificationStatus.Unverified,
]

const selectOptionsByType = (type: ProviderCredentialingVerificationType | null) => {
  const selectOptions = composeSelectOptionsFromEnum(ProviderCredentialingVerificationStatus)
  if (!type) return selectOptions
  if (type === ProviderCredentialingVerificationType.Education) {
    return selectOptions
  }
  return Object.keys(selectOptions).filter(option =>
    basicSelectOptions.includes(option as ProviderCredentialingVerificationStatus),
  )
}

const validationSchema = z.object({
  verificationStatus: z.nativeEnum(ProviderCredentialingVerificationStatus),
  verifiedAt: z.coerce.date(),
  source: z.string().optional().nullable(),
})

type Props = {
  selectedItem: CredentialingChecklistDataPoint
}

export const CredentialingVerificationForm = ({ selectedItem }: Props) => {
  const { verifications } = useCredentialingChecklistContext()
  const [saveProviderCredentialingVerification, { loading: isSaving }] =
    useSaveProviderCredentialingVerificationMutation()
  const typeText = capitalize(selectedItem.verificationType ?? '')

  const exitingVerification = verifications?.find(v => v.verifiableId === selectedItem.verifiableId)

  const currentVerification = {
    id: exitingVerification?.id ?? null,
    source: exitingVerification?.source ?? null,
    verifiableId: exitingVerification?.verifiableId ?? '',
    providerVerifiableDataId: exitingVerification?.providerVerifiableDataId ?? '',
    verificationStatus: exitingVerification?.verificationStatus ?? ProviderCredentialingVerificationStatus.Unverified,
    type: exitingVerification?.type ?? ProviderCredentialingVerificationType.WorkHistory,
    verifiedAt: exitingVerification?.verifiedAt ?? new Date(),
  }

  const handleSubmit = async (values: ProviderCredentialingVerificationInput) => {
    try {
      await saveProviderCredentialingVerification({
        variables: {
          data: {
            ...values,
          },
        },
      })
      toast.success(`${typeText} updated`)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, `Error updating ${typeText} record`))
    }
  }

  return (
    <Stack direction={'column'} sx={{ height: '100%', p: 1, flex: 1 }}>
      <Formik
        enableReinitialize
        initialValues={currentVerification}
        validationSchema={toFormikValidationSchema(validationSchema)}
        onSubmit={handleSubmit}
      >
        {formikProps => {
          const { values, touched, errors, setFieldValue, getFieldProps } = formikProps

          return (
            <Form>
              <Stack direction={'row'} gap={3} sx={{ justifyContent: 'flex-start', p: 1, flex: 1 }}>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <DatePicker
                    sx={{ width: '280px' }}
                    label="Verified Date"
                    value={moment(values.verifiedAt)}
                    error={touched.verifiedAt && !!errors.verifiedAt}
                    helperText={composeHelperText(`${typeText} verified date`, errors.verifiedAt, !!touched.verifiedAt)}
                    onChange={date => setFieldValue('verifiedAt', date?.toDate(), true)}
                  />
                </Box>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <Select label="Verfication Status" {...getFieldProps('verificationStatus')}>
                    {Object.entries(
                      selectOptionsByType(selectedItem.verificationType as ProviderCredentialingVerificationType),
                    ).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Select>

                  <HelperText error={!!errors.verificationStatus} urgent={!!errors.verificationStatus}>
                    {composeHelperText(
                      `${typeText} verification status`,
                      errors.verificationStatus,
                      !!touched.verificationStatus,
                    )}
                  </HelperText>
                </Box>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <TextField
                    label="Source"
                    {...getFieldProps('source')}
                    helperText={composeHelperText(`${typeText} source`, errors.source, !!touched.source)}
                    autoFocus
                  />
                </Box>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <FillButton type="submit" isLoading={isSaving}>
                    Save
                  </FillButton>
                </Box>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Stack>
  )
}
